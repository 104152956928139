(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
/**
 * Main module
 */

(function(window, undefined) {
  'use strict';

  var socketio = require('./receiver/socketio'),
      errors = require('./receiver/errors'),
      supervisor = require('./receiver/supervisor'),
      console = require('./receiver/console'),
      document = window.document;

  window.console = console;
  errors.enable();

  document.addEventListener('DOMContentLoaded', function() {
    supervisor.ready();
    // OK, ready, connect to socket.io
    console.log('[Dashkiosk] dashkiosk ready, connect to socket.io server');
    socketio.connect();
  });

})(window);

},{"./receiver/console":2,"./receiver/errors":3,"./receiver/socketio":8,"./receiver/supervisor":9}],2:[function(require,module,exports){
module.exports = (function(window) {
  'use strict';

  if (window.JSInterface && window.JSInterface.log) {
    var log = function(level) {
      return function(message) {
        window.JSInterface.log(level + ': ' + message);
      };
    };
    return {
      log: log('log'),
      info: log('info'),
      warn: log('warn'),
      error: log('error'),
      debug: log('debug')
    };
  } else {
    return window.console;
  }
})(window);

},{}],3:[function(require,module,exports){
module.exports = (function(window) {
  'use strict';

  return {
    enable: function() {
      window.onerror = function(message, url, line, column, errorObj) {
        try {
          if (errorObj === undefined || errorObj === null) {
            // We won't do anything more sensible than the default action
            return false;
          }
          console.error('[Dashkiosk] ' +
                        '(' + url + ':' + line + ':' + column + '): ' + message);
          console.error('[Dashkiosk] stack:\n' + errorObj.stack);
          return true;
        } finally {
          console.error('[Dashkiosk] fatal unexpected error, let\'s reload');
          window.setTimeout(function() {
            window.location.reload();
          }, 1000);
        }
      };
    }
  };

})(window);

},{}],4:[function(require,module,exports){
module.exports = (function(window, undefined) {
  'use strict';

  var Viewport = require('./viewport');

  function Iframe(dashboard, options) {
    this.dashboard = dashboard;
    this.ready = options.ready;
    this.el = document.createElement('iframe');
    document.body.appendChild(this.el);

    var vp = new Viewport(dashboard.viewport, this.el);
    this.load = function() {
      this.el.removeEventListener('load', this.load, false);
      if (dashboard.delay) {
        console.info('[Dashkiosk] iframe ready ' +
                     this.el.getAttribute('src') +
                     ', but wait ' +
                     dashboard.delay + 's');
        window.setTimeout(function() {
          this.show();
        }.bind(this), dashboard.delay * 1000);
      } else {
        this.show();
      }
    }.bind(this);
    this.el.addEventListener('load', this.load, false);

    this.el.setAttribute('scrolling', 'no');
    this.el.setAttribute('frameborder', '0');

    // Adapt iframe to match desired viewport
    vp.update();

    // Load the URL
    this.el.setAttribute('src', dashboard.url);
  }

  Iframe.prototype.displayed = function() {
    return this.el.classList.contains('show');
  };

  Iframe.prototype.remove = function() {
    this.el.removeEventListener('load', this.load, false);
    if (this.el.classList.contains('show')) {
      this.el.classList.remove('show');
      window.setTimeout(function() {
        if (this.el.parentNode) {
          this.el.parentNode.removeChild(this.el);
        }
      }.bind(this), 1001); // This is more reliable than relying on transitionend
    } else {
      if (this.el.parentNode) {
        this.el.parentNode.removeChild(this.el);
      }
    }
  };

  Iframe.prototype.show = function() {
    console.info('[Dashkiosk] iframe ready ' + this.el.getAttribute('src'));
    if (this.ready) {
      this.ready();
    }
    this.el.classList.add('show');
  };

  function Queue(options) {
    this.queue = [];
    this.ready = options.ready;
  }

  Queue.prototype.flush = function() {
    var iframe;
    while ((iframe = this.queue.shift())) {
      iframe.remove();
    }
  };

  Queue.prototype.push = function(dashboard) {
    // Remove the first iframe if it is not loaded
    var iframe = this.queue.shift();
    if (iframe !== undefined) {
      if (iframe.displayed()) {
        // Oops, it is loaded, put it back.
        this.queue.unshift(iframe);
      } else {
        // Remove it, we will put ours instead
        iframe.remove();
      }
    }

    // Don't do anything if the current dashboard is the same.
    if (this.queue.length >= 1) {
      var current = this.queue[this.queue.length - 1];
      if (current &&
          current.displayed() &&
          (JSON.stringify(current.dashboard) === JSON.stringify(dashboard))) {
        console.info('[Dashkiosk] same dashboard already displayed, do nothing');
        return;
      }
    }

    // Build a new frame
    iframe = new Iframe(dashboard, {
      ready: function() {
        // Sanity check: are we the first iframe?
        if (iframe !== this.queue[0]) {
          console.warn('[Dashkiosk] BUG: request to display a new iframe which is not in our queue (' +
                       iframe + ', ' + this.queue);
          iframe.remove();
          return;
        }

        // Remove all other frames from the queue
        while (this.queue.length > 1) {
          var oldIframe = this.queue.pop();
          oldIframe.remove();
        }
        this.ready();
      }.bind(this)
    });

    // Put it in the queue
    this.queue.unshift(iframe);
  };

  return Queue;

})(window);

},{"./viewport":10}],5:[function(require,module,exports){
module.exports = (function(window, undefined) {
  'use strict';

  // Using local storage if available
  function LocalStorage() {
  }
  var hasLocalStorage = true;
  try {
    window.localStorage.setItem('testing', 'testing');
    if (window.localStorage.getItem('testing') !== 'testing') {
      hasLocalStorage = false;
    }
    window.localStorage.removeItem('testing');
  } catch (e) {
    hasLocalStorage = false;
  }
  if (hasLocalStorage) {
    LocalStorage.prototype.getItem = function(key) {
      return window.localStorage.getItem(key);
    };
    LocalStorage.prototype.setItem = function(key, value) {
      window.localStorage.setItem(key, value);
      return true;
    };
  } else {
    LocalStorage.prototype.getItem = function() { return undefined; };
    LocalStorage.prototype.setItem = function() { return false; };
  }

  // Cookie storage
  function CookieStorage(name) {
    this.name = name;
  }

  CookieStorage.prototype._write = function(value) {
    var days = 365,
        date = new Date();
    date.setTime(date.getTime() + (days*24*60*60*1000));

    var expires = '; expires='+date.toGMTString();
    window.document.cookie = this.name + '=' + value + expires + '; path=/';
  };

  CookieStorage.prototype._read = function() {
    var nameEQ = this.name + '=',
        ca = document.cookie.split(';'),
        i, c;

    for (i=0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  CookieStorage.prototype.getItem = function(key) {
    var data = JSON.parse(this._read() || '{}');
    return data[key];
  };

  CookieStorage.prototype.setItem = function(key, value) {
    var data = JSON.parse(this._read() || '{}');
    data[key] = value;
    this._write(JSON.stringify(data));
    return false;               // We are unsure if cookie will stay.
  };

  function HashStorage() {
  }

  HashStorage.prototype._escapeRegExp = function(str) {
    return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  HashStorage.prototype.getItem = function(key) {
    var ekey = this._escapeRegExp(key),
        regex = new RegExp('^' + ekey + '=(.*)$'),
        candidates = window.location.hash.slice(1).split(/[,#]/)
          .map(function(item) {
            var mo = regex.exec(item);
            if (!mo) {
              return null;
            }
            return mo[1];
          })
          .filter(function(item) { return item !== null; });
    if (candidates.length === 0) {
      return undefined;
    }
    return candidates[0];
  };

  HashStorage.prototype.setItem = function(key, value) {
    var ekey = this._escapeRegExp(key),
        regex = new RegExp('^' + ekey + '=(.*)$'),
        hash = window.location.hash
          .slice(1)
          .split(/[,#]/)
          .filter(function(item) {
            return !regex.exec(item);
          })
          .join('#')
        .concat(['#' + ekey + '=' + value]);
    window.location.hash = hash;
    return true;
  };

  var cookies = new CookieStorage('dashkiosk'),
      local = new LocalStorage(),
      hash = new HashStorage();

  return {
    getItem: function(key) {
      return hash.getItem(key) || local.getItem(key) || cookies.getItem(key);
    },
    setItem: function(key, value) {
      return cookies.setItem(key, value) || local.setItem(key, value) || hash.setItem(key, value);
    }
  };


})(window);

},{}],6:[function(require,module,exports){
module.exports = (function(window, undefined) {
  'use strict';

  return {
    hide: function() {
      Array.prototype.forEach.call(
        document.querySelectorAll('.osd'),
        function(osd) { osd.classList.remove('show'); });
    },
    show: function(text) {
      document.querySelector('.osd.technical').innerHTML = (
          '<p>clientWidth/Height: ' +
            (window.document.documentElement.clientWidth || '???') + '×' +
            (window.document.documentElement.clientHeight || '???') +
            '</p><p>' +
            '<p>innerWidth/Height: ' +
            (window.innerWidth || '???') + '×' +
            (window.innerHeight || '???') +
            '</p><p>' +
            '<p>screenWidth/Height: ' +
            (window.screen.width || '???') + '×' +
            (window.screen.height || '???') +
          '</p>'
      );
      document.querySelector('.osd.text').textContent = text;
      Array.prototype.forEach.call(
        document.querySelectorAll('.osd'),
        function(osd) { osd.classList.add('show'); });
    }
  };

})(window);

},{}],7:[function(require,module,exports){
module.exports = (function(undefined) {
  'use strict';

  var IframeQueue = require('./iframe-queue'),
      queue = new IframeQueue({
    ready: function() {
      document.querySelector('#loading').classList.remove('show');
    }
  });

  /* Display connecting symbol */
  function connecting() {
    document.querySelector('.connecting').classList.add('show');
  }
  function connected() {
    document.querySelector('.connecting').classList.remove('show');
  }

  /* Display the given dashboard */
  function dashboard(d) {
    // Check URL validity
    if (typeof d.url !== 'string') {
      console.warn('[Dashkiosk] received an URL without target: ' + d.url);
      return;
    }
    // Push it
    queue.push(d);
  }

  return {
    connecting: connecting,
    connected: connected,
    dashboard: dashboard
  };

})();

},{"./iframe-queue":4}],8:[function(require,module,exports){
module.exports = (function(window, io, undefined) {
  'use strict';
  /* Socket.io related functions */

  var screen = require('./screen'),
      osd = require('./osd'),
      localStorage = require('./localstorage'),
      Viewport = require('./viewport');

  function connect() {
    var socket = io.connect(window.location.origin + '/displays', {
      'reconnection limit': 60*1000,
      'max reconnection attempts': Infinity
    });

    socket.on('connect', function() {
      console.info('[Dashkiosk] connected to socket.io server');
      screen.connected();

      // We register by providing a blob the server handed us to
      // remember us. If we get null, that's fine, the server will see
      // us as a new fresh screen.
      var blob = localStorage.getItem('register') || null;
      socket.emit('register', {
        blob: blob
      }, function(data) {
        console.info('[Dashkiosk] registered to server');
        localStorage.setItem('register', data);
      });
    });

    // Log various events
    socket.on('connecting', function() {
      console.info('[Dashkiosk] connect in progress to socket.io server');
      screen.connecting();
    });
    socket.on('connect_failed', function() {
      console.warn('[Dashkiosk] unable to connect to socket.io server');
      window.location.reload();
    });
    socket.on('error', function(message) {
      console.warn('[Dashkiosk] uncaught error with socket.io server: ' + message);
      window.location.reload();
    });
    socket.on('reconnecting', function(delay, attempts) {
      console.info('[Dashkiosk] reconnect in progress to socket.io server (next: ' +
                   delay + ' attempts: ' + attempts + ')');
      screen.connecting();
    });
    socket.on('reconnect_failed', function() {
      console.warn('[Dashkiosk] unable to reconnect to socket.io server');
      window.location.reload();
    });

    socket.on('disconnect', function() {
      console.warn('[Dashkiosk] connection to socket.io lost');
      screen.connecting();
    });

    socket.on('dashboard', function(dashboard) {
      console.info('[Dashkiosk] should display dashboard ' + dashboard.url);
      screen.dashboard(dashboard);
    });

    socket.on('reload', function() {
      console.info('[Dashkiosk] reload requested');
      window.location.reload();
    });

    socket.on('osd', function(text) {
      if (text === undefined || text === null) {
        console.info('[Dashkiosk] hide OSD');
        osd.hide();
      } else {
        console.info('[Dashkiosk] display OSD');
        osd.show(text);
      }
    });

    socket.on('viewport', function(vp) {
      console.info('[Dashkiosk] viewport change to ' + (vp || 'default') + ' requested');
      new Viewport(vp).update();
    });
  }

  return {
    connect: connect
  };

})(window, io);

},{"./localstorage":5,"./osd":6,"./screen":7,"./viewport":10}],9:[function(require,module,exports){
module.exports = (function(window) {
  'use strict';

  var maxDelay = 5000;          // maximum delay between two probes

  // Extract timeout from location hash
  function timeoutFromLocationHash() {
    var timeouts = window.location.hash.slice(1).split(/[,#]/)
          .map(function(item) {
            var mo = /^timeout=(\d+)$/.exec(item);
            if (!mo) {
              return null;
            }
            return parseInt(mo[1], 10);
          })
          .filter(function(item) { return item !== null; });
    if (timeouts.length === 0) {
      return undefined;
    }
    return timeouts[0];
  }

  var lastTimeout = null,
      ready = function() {
        // Tell through a message
        console.debug('[Dashkiosk] send heartbeat to supervisor');
        if (window.parent && window.parent.postMessage) {
          window.parent.postMessage('ready', '*');
        }
        // Tell through a JSInterface
        if (window.JSInterface && window.JSInterface.ready) {
          window.JSInterface.ready();
        }
        // Heartbeat
        var timeout;
        if (window.JSInterface && window.JSInterface.timeout) {
          timeout = window.JSInterface.timeout();
        }
        timeout = timeout || timeoutFromLocationHash();
        if (lastTimeout) {
          window.clearTimeout(lastTimeout);
        }
        if (timeout) {
          var delay = Math.min(timeout * 0.3, maxDelay);
          lastTimeout = window.setTimeout(ready, delay);
        }
      };

  return {
    ready: ready
  };

})(window);

},{}],10:[function(require,module,exports){
module.exports = (function(window) {
  'use strict';

  window.addEventListener('resize', function() {
    // On resize, find any element with a simulatedViewport attribute
    // and update it. We ensure we start with the root one.
    var de = window.document.documentElement;
    var vps = de.querySelectorAll('[data-simulated-viewport]');
    var root = new Viewport(de.dataset.simulatedViewport);

    root.update();
    for (var i = 0; i < vps.length; ++i) {
      var vp = new Viewport(vps[i].dataset.simulatedViewport, vps[i]);
      vp.update();
    }
  });

  // Create a new viewport for the provide `el' element.
  function Viewport(spec, el) {
    this.el = el || window.document.documentElement;
    this.width = this.height = null;
    if (spec) {
      var dimensions = spec.split('x');
      this.width = dimensions[0] || null;
      this.height = dimensions[1] || null;
      this.el.dataset.simulatedViewport = spec;
    } else {
      if (this.el.dataset.simulatedViewport !== undefined) {
        delete this.el.dataset.simulatedViewport;
      }
    }
  }

  Viewport.prototype.update = function() {
    // Support of viewport is highly dependant of the client. Browsers
    // do that, but other clients don't.
    var de = window.document.documentElement,
        el = this.el,
        style = el.style,
        reset = function() {
          style.transform = style.mozTransform = style.webkitTransform = '';
          style.width = '';
          style.height = '';
        };
    if (!this.width && !this.height) {
      reset();
      return;
    }
    var cw = (el !== de && parseInt(de.style.width)) || de.clientWidth,
        ch = (el !== de && parseInt(de.style.height)) || de.clientHeight,
        tw = this.width || this.height * cw / ch,
        th = this.height || this.width * ch / cw,
        scale = Math.min(cw / tw, ch / th),
        transform = '',
        tag = el.tagName;
    if (scale - 1 < 0.02 && scale - 1 > -0.02) {
      console.debug('[Dashkiosk] no need to rescale ' + tag);
      reset();
      return;
    }
    transform = 'scaleX(' + scale + ') scaleY(' + scale +')';
    console.debug('[Dashkiosk] apply following transform for ' + tag + ': ' + transform);
    style.transformOrigin =  style.mozTransformOrigin = style.webkitTransformOrigin = 'top left';
    style.transform = style.mozTransform = style.webkitTransform = transform;
    style.width = Math.round(cw / scale) + 'px';
    style.height = Math.round(ch / scale) + 'px';
  };

  return Viewport;

})(window);

},{}]},{},[1]);
